<template>
    <el-row :gutter="10">
        <el-col :span="4">
            <el-divider style="margin-bottom:1px;">{{ $t("lang.我的工程") }}</el-divider>
            <el-menu id="myWCXWProject" :style="'height:' + myHeight + 'px;overflow-y: auto;border:solid 1px #e6e6e6'" @select="handleSelectProject" :default-active="projectId">
                <el-menu-item v-for="(item, index) in myProject" :key="index" :index="item.id.toString()">
                    <i class="el-icon-menu"></i>{{ item.projectName }}</el-menu-item>
            </el-menu>
        </el-col>
        <el-col :span="20">
            <el-divider>{{ $t("lang.测位列表") }}</el-divider>
            <el-empty v-if="(!projectId)">
                <template #description>
                    <div style="font-size:30px;font-weight:bolder;color: red;">{{ $t("lang.请选择工程") }}</div>
                </template>
            </el-empty>
            <el-empty v-else-if="(myCW.length == 0)">
                <template #description>
                    <div style="font-size:30px;font-weight:bolder;color: red;">{{ $t("lang.请至工程管理增加测位") }}</div>
                </template>
            </el-empty>
            <el-tabs v-else v-loading="loading" :element-loading-text="$t('lang.加载中')" element-loading-spinner="el-icon-loading" v-model="selectCWIndex" type="border-card" stretch :style="'height:' + myHeight + 'px;'" @tab-click="loadCWData">
                <el-tab-pane v-for="(item, index) in myCW" :key="index" :label="item.name" :name="index.toString()"></el-tab-pane>
                <div>
                    <div style="display:flex;">
                        <div style="display:flex;flex-direction:column;">
                            <div style="text-align:center;">{{ $t("lang.选择日期") }}</div>
                            <div style="margin-top:20px;">
                                <el-date-picker v-model="searchRange" @change="loadCWData" type="datetimerange" :picker-options="pickerOptions" :range-separator="$t('lang.至')" :start-placeholder="$t('lang.开始日期')" :end-placeholder="$t('lang.结束日期')" align="right">
                                </el-date-picker>
                            </div>
                            <el-row type="flex" justify="center" style="margin-top: 20px;">
                                <el-button type="primary" icon="el-icon-download" @click="exportExcel">{{ $t("lang.导出峰值数据") }}</el-button>
                            </el-row>
                            <el-row type="flex" justify="center" style="margin-top: 20px;">
                                <el-alert :title="$t('lang.原始数据导出见我的设备')" show-icon type="warning" :closable="false" center>
                                </el-alert>
                            </el-row>
                        </div>
                        <div style="flex:1;padding-left:10px;">
                            <div id="cwChart"></div>
                        </div>
                    </div>
                    <el-empty v-if="(myCWData.length == 0)" :image-size="30"><template #description>
                            <div style="font-size:30px;font-weight:bolder;color: red;">{{ $t("lang.该测位下无测点") }}</div>
                        </template></el-empty>
                    <el-tabs v-else v-model="selectCDId" type="border-card" tab-position="bottom" stretch>
                        <el-tab-pane v-for="(item, index) in myCWData" :key="index" :label="item.name" :name="item.id.toString()">
                            <el-descriptions :column="3" :border="true" v-if="item.maxObj">
                                <el-descriptions-item>
                                    <template slot="label"><i class="el-icon-set-up"></i>{{ $t("lang.设备序列号") }}</template>{{ item.serialNumber }}</el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label"><i class="iconfont icon-tongdaoguanli"></i>{{ $t("lang.通道") }}</template>{{ item.passageway }} </el-descriptions-item>
                                <el-descriptions-item content-class-name="cd-description">
                                    <template slot="label"><i class="iconfont icon-wendu"></i>{{ $t("lang.最新温度") }}</template>{{ item.lastObj.value }}℃ </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label"><i class="el-icon-timer"></i>{{ $t("lang.返回时间") }}</template>{{ item.lastObj.time }} </el-descriptions-item>
                                <el-descriptions-item content-class-name="cd-description">
                                    <template slot="label"><i class="iconfont icon-wendu"></i>{{ $t("lang.峰值") }}</template>{{ item.maxObj.value }}℃ </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label"><i class="el-icon-timer"></i>{{ $t("lang.峰值时间") }}</template>{{ item.maxObj.time }}</el-descriptions-item>
                                <el-descriptions-item content-class-name="cd-description"> <template slot="label"><i class="iconfont icon-wendu"></i>{{ $t("lang.谷值") }}</template>{{ item.minObj.value }}℃</el-descriptions-item>
                                <el-descriptions-item><template slot="label"><i class="el-icon-timer"></i>{{ $t("lang.谷值时间") }}</template>{{ item.minObj.time }}</el-descriptions-item>
                                <el-descriptions-item content-class-name="cd-description">
                                    <template slot="label"><i class="iconfont icon-pingjunzhi"></i>{{ $t("lang.平均值") }}</template>{{ item.avgValue.toFixed(1) }}℃
                                </el-descriptions-item>
                            </el-descriptions>
                            <el-empty v-else :image-size="20">
                                <template #description>
                                    <div style="font-size:15px;font-weight:bolder;color: red;"> {{ $t("lang.测点无数据") + '(' + item.serialNumber + '(' + item.passageway + '))' }}</div>
                                </template>
                            </el-empty>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </el-tabs>
        </el-col>
    </el-row>
</template>

<script>
import Vue from "vue";
// 引入样式
import "vue-easytable/libs/theme-default/index.css";
// 引入组件库
import VueEasytable from "vue-easytable";
import HighCharts from "highcharts";
import moment from "moment";
import dark from "highcharts/themes/dark-unica";
import exportHighChart from "highcharts/modules/exporting";
import exportOffHighChart from "highcharts/modules/offline-exporting";
import HighchartsHanHua from "@/util/highcharts-lang-zh_CN.js";
exportHighChart(HighCharts);
exportOffHighChart(HighCharts);
dark(HighCharts);
HighchartsHanHua.HighchartsHanHua(HighCharts);
HighCharts.setOptions({ global: { useUTC: false } });

Vue.use(VueEasytable);
export default {
    props: ["projectId"],
    data() {
        let that = this;
        return {
            myHeight: 0,
            myProject: [],
            selectProject: {},
            myCW: [],
            selectCWIndex: null,
            selectCDId: null, //这里其实应该也写成selectCDIndex，但是怕出错，又没影响所以只改了selectCWIndex
            myCWData: [],
            searchRange: [],
            loading: false,
            pickerOptions: {
                shortcuts: [
                    {
                        text: "1" + that.$t("lang.天"),
                        onClick(picker) {
                            picker.$emit("pick", [
                                moment().startOf("day").toDate(),
                                moment().startOf("day").add(1, "days").toDate(),
                            ]);
                        },
                    },
                    {
                        text: "2" + that.$t("lang.天"),
                        onClick(picker) {
                            picker.$emit("pick", [
                                moment().startOf("day").add(-1, "days").toDate(),
                                moment().startOf("day").add(1, "days").toDate(),
                            ]);
                        },
                    },
                    {
                        text: "3" + that.$t("lang.天"),
                        onClick(picker) {
                            picker.$emit("pick", [
                                moment().startOf("day").add(-2, "days").toDate(),
                                moment().startOf("day").add(1, "days").toDate(),
                            ]);
                        },
                    },
                    {
                        text: "7" + that.$t("lang.天"),
                        onClick(picker) {
                            picker.$emit("pick", [
                                moment().startOf("day").add(-6, "days").toDate(),
                                moment().startOf("day").add(1, "days").toDate(),
                            ]);
                        },
                    },
                    {
                        text: "15" + that.$t("lang.天"),
                        onClick(picker) {
                            picker.$emit("pick", [
                                moment().startOf("day").add(-14, "days").toDate(),
                                moment().startOf("day").add(1, "days").toDate(),
                            ]);
                        },
                    },
                    {
                        text: "30" + that.$t("lang.天"),
                        onClick(picker) {
                            picker.$emit("pick", [
                                moment().startOf("day").add(-29, "days").toDate(),
                                moment().startOf("day").add(1, "days").toDate(),
                            ]);
                        },
                    },
                    {
                        text: that.$t("lang.项目起止"),
                        onClick(picker) {
                            picker.$emit("pick", [
                                moment(that.selectProject.beginDate).toDate(),
                                moment(that.selectProject.endDate).toDate(),
                            ]);
                        },
                    },
                ],
            },
        };
    },
    methods: {
        handleSelectProject(v) {
            if (v != this.projectId) {
                this.$router.push("/DeviceData/WXCW03MyProject/" + v);
            }
        },
        exportExcel() {
            let that = this;
            if (that.searchRange && that.searchRange.length > 1) {
                let tmploading = that.$loading({
                    lock: true,
                    text: that.$t("lang.加载中"),
                    spinner: "el-icon-loading",
                });
                that.axios
                    .post(
                        "WXCW03/ExportFengZhiGuZhi",
                        {
                            id: that.myCW[parseInt(that.selectCWIndex)].id,
                            beginDate: that.searchRange[0],
                            endDate: that.searchRange[1],
                        },
                        {
                            responseType: "blob",
                        }
                    )
                    .then((response) => {
                        tmploading.close();
                        let b = new Blob([response.data], {
                            type: "application/vnd.ms-excel;charset=utf-8",
                        });
                        let downloadElement = document.createElement("a");
                        let href = window.URL.createObjectURL(b); // 创建下载的链接
                        downloadElement.href = href;
                        downloadElement.download =
                            that.myCW[parseInt(that.selectCWIndex)].name +
                            "(" +
                            moment(that.searchRange[0]).format("YYYY-MM-DD HH:mm:ss") +
                            "-" +
                            moment(that.searchRange[1]).format("YYYY-MM-DD HH:mm:ss") +
                            ").xlsx"; // 下载后文件名
                        document.body.appendChild(downloadElement);
                        downloadElement.click(); // 点击下载
                        document.body.removeChild(downloadElement); // 下载完成移除元素
                        window.URL.revokeObjectURL(href); // 释放掉blob对象
                    });
            } else {
                that.$message({ type: "error", message: that.$t("lang.请选择日期") });
            }
        },
        loadCharts(mapData) {
            //console.log(mapData);
            let that = this;
            HighCharts.chart("cwChart", {
                lang: {
                    viewFullscreen: that.$t("lang.全屏"),
                    printChart: that.$t("lang.打印图表"),
                    downloadJPEG: that.$t("lang.导出JPEG"),
                    downloadPDF: that.$t("lang.导出PDF"),
                    downloadPNG: that.$t("lang.导出PNG"),
                    downloadSVG: that.$t("lang.导出SVG"),
                },
                tooltip: {
                    formatter: function () {
                        return (
                            this.series.name +
                            "<br />" +
                            that.$t("lang.温度") +
                            "：" +
                            this.y +
                            "℃<br />" +
                            that.$t("lang.时间") +
                            "：" +
                            moment(this.x).format("YYYY-MM-DD HH:mm:ss")
                        );
                    },
                },
                plotOptions: { series: { turboThreshold: 0 } }, //大于一千个点要用这个。否则不显示。
                title: { text: that.$t("lang.温度曲线图") },
                credits: { enabled: false },
                //global: { useUTC: false },这里要在开头的时候初始化，而不是这里。
                yAxis: { title: { text: that.$t("lang.温度") } },
                xAxis: { type: "datetime" },
                series: mapData,
            });
        },
        loadProject() {
            let that = this;
            that.loadSearchDate();
            that.axios
                .post("WXCW03/GetCWGL", {
                    pageSize: 1000,
                    docId: parseInt(that.projectId),
                })
                .then((response) => {
                    that.myCW = response.data.data.data;
                    if (that.myCW.length > 0) {
                        that.selectCWIndex = "0";
                        that.loadCWData();
                    }
                });
        },
        loadCWData() {
            let that = this;
            if (that.searchRange) {
                that.loading = true;
                //在选择测位的时候把事件初始化一下
                that.axios
                    .post("WXCW03/GetCWData", {
                        id: that.myCW[parseInt(that.selectCWIndex)].id,
                        beginDate: that.searchRange[0],
                        endDate: that.searchRange[1],
                    })
                    .then((response) => {
                        that.loading = false;
                        that.myCWData = response.data.data;
                        if (that.myCWData.length > 0) {
                            that.selectCDId = that.myCWData[0].id.toString();
                        }
                        that.loadCharts(that.myCWData);
                    });
            }
        },
        loadSearchDate() {
            let that = this;
            that.selectProject = that.myProject.find((o) => o.id == that.projectId);
            that.searchRange = [
                moment(that.selectProject.beginDate).toDate(),
                moment(that.selectProject.endDate).toDate(),
            ];
        },
    },
    watch: {
        projectId() {
            if (this.projectId) this.loadProject();
        },
    },
    mounted() {
        let that = this;
        that.myHeight = that.$parent.$el.offsetHeight - 120;
        //获取我的项目
        that.axios
            .post("WXCW03/GetMyProject", {
                pageSize: 1000,
            })
            .then((response) => {
                that.myProject = response.data.data.data;
                if (that.projectId) {
                    that.loadProject(that.projectId);
                }
            });
    },
};
</script>

<style>
.cd-description {
    background-color: #fde2e2;
}

#myWCXWProject .is-active {
    background-color: #409eff;
    color: white;
}

#myWCXWProject .el-menu-item {
    border-bottom: solid 1px #e6e6e6;
}
</style>
